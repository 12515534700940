import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Head from 'next/head';
import { nextImageLoader } from '../../cms/images';
import { SourceImage } from './styles';

/**
 * Composes responsive images for an array of sources. Pass picture as
 * a prop to use this for art direction. Use a normal srcset if just
 * swapping identical images for diff. screen sizes
 *
 *
 * @prop src String: fallback image URL
 * @prop srcSet [object]: an array of sources
 *    e.g. [{ src: 'cat.jpg', pixelDensity: 1 }, { src: 'cat@2x.jpg'
 *    pixelDensity: 2 }]
 *    [{ srcSet: [{ srcs, above }], media: '(min-width: 640px)' }, ...] for picture
 * @prop picture Boolean: render a picture el instead of img srcset
 * @prop sizes String: Size of viewport the picture should occupy per
 * media query
 * @prop alt String: Alt text
 */

const composeSrcSet = srcSet =>
  srcSet.reduce((acc, cur, idx) => {
    let str = cur.src;
    if (cur.width) {
      str += ` ${cur.width}w`;
    } else if (cur.pixelDensity) {
      str += ` ${cur.pixelDensity}x`;
    }
    if (idx !== srcSet.length - 1) {
      str += ', ';
    }
    return acc + str;
  }, '');

const getMinWidth = (srcSets, curIdx) => {
  const prevMax = srcSets[curIdx - 1]
    ? parseInt(srcSets[curIdx - 1].media.split(': ')[1].split('px')[0])
    : null;
  if (prevMax && typeof prevMax === 'number') return String(prevMax + 1);
  return '';
};

const ResponsivePicture = ({
  src,
  alt,
  srcSet: srcSets,
  className,
  objectFit,
  priority,
}) => {
  const sources = [];
  const headLinks = [];

  srcSets.forEach(({ media, srcSet, sizes }, i) => {
    const minWidth = getMinWidth(srcSets, i);

    sources.push(
      <source
        key={i}
        media={media}
        srcSet={composeSrcSet(srcSet)}
        sizes={sizes}
      />,
    );

    headLinks.push(
      <link
        key={'link-' + i}
        rel="preload"
        as="image"
        imagesrcset={composeSrcSet(srcSet)}
        imagesizes={sizes}
        media={media + (minWidth ? ' and (min-width: ' + minWidth + 'px)' : '')}
        // TODO(James) convert to fetchPriority when react@>=18.3.0
        fetchpriority={priority ? 'high' : 'low'}
      />,
    );
  });

  return (
    <>
      <Head>{headLinks}</Head>
      <picture>
        {sources}
        <SourceImage
          src={src}
          alt={alt}
          className={className}
          loading={priority ? null : 'lazy'}
        />
      </picture>
    </>
  );
};

ResponsivePicture.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  srcSet: PropTypes.arrayOf(
    PropTypes.shape({
      media: PropTypes.string,
      sizes: PropTypes.string,
      srcSet: PropTypes.arrayOf(
        PropTypes.shape({
          src: PropTypes.string.isRequired,
          width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          pixelDensity: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
        }),
      ).isRequired,
    }),
  ),
  className: PropTypes.string,
  objectFit: PropTypes.oneOf(['contain', 'cover', 'none']),
  priority: PropTypes.bool,
};

const ResponsiveNextImage = ({
  src,
  alt,
  className,
  width,
  height,
  sizes = '100vw',
  fill = false,
  style,
  objectFit,
  priority,
}) => {
  // If our image is imported internally, don't run it through the
  // storyblok image loader
  const isStoryblokImage = src?.match?.(/^https:\/\/(a|img2).storyblok.com/);

  // TODO(James) is this a terrible idea?
  // Storyblok doesn't provide dimensions with SVGs.
  // How can we support SVGs & non-SVGs from the CMS?
  if (isStoryblokImage && src.endsWith('.svg')) {
    return <SourceImage src={src} alt={alt} />;
  }

  // Static imports of SVGs can't be blurred
  const canBlur = typeof src === 'object' && !src.src.endsWith('.svg');

  return (
    <Image
      loader={isStoryblokImage ? nextImageLoader : undefined}
      src={src}
      alt={alt}
      className={className}
      width={width}
      height={height}
      sizes={sizes} //* Sizes can be overridden but defaults to 100vw
      fill={fill}
      style={{
        ...(fill ? { height: '100%' } : { height: 'auto' }),
        ...(objectFit && { objectFit: objectFit }), //* If there is an object fit value, it will be added to the components styles prop
        ...(style && { ...style }), //* Styling can be passed in as a prop then spread into the style prop of the component
      }}
      priority={priority}
      placeholder={canBlur ? 'blur' : undefined}
      blurDataURL={canBlur ? src.src : undefined}
    />
  );
};

ResponsiveNextImage.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    // This is part of the shape provided by Nextjs's static image imports.
    PropTypes.shape({
      src: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fill: PropTypes.bool,
  sizes: PropTypes.string,
  style: PropTypes.object,
  objectFit: PropTypes.oneOf(['contain', 'cover', 'none']),
  priority: PropTypes.bool,
};

/**
 * Guidelines for using ResponsiveImage
 * - When fill is true, Height will default to '100%' rather than 'auto'
 * - To add styling to component or to override styles, you must pass in a style object ex: style={{ width: '100%' }}
 */
export const ResponsiveImage = ({ picture = false, src, alt, ...props }) => {
  const isBase64Svg =
    typeof src === 'string' && src.includes('data:image/svg+xml;base64');

  return picture ? (
    <ResponsivePicture src={src} alt={alt} {...props} />
  ) : isBase64Svg ? (
    <img src={src} alt={alt} {...props} /> // eslint-disable-line @next/next/no-img-element
  ) : (
    <ResponsiveNextImage src={src} alt={alt} {...props} />
  );
};

ResponsiveImage.displayName = 'ResponsiveImage';

ResponsiveImage.propTypes = {
  picture: PropTypes.bool,
  src: PropTypes.oneOfType([
    PropTypes.string,
    // This is part of the shape provided by Nextjs's static image imports.
    PropTypes.shape({
      src: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  alt: PropTypes.string.isRequired,
};
