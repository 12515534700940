import { useState } from 'react';

export const useAddonModal = modals => {
  const [modal, setModal] = useState({
    shown: false,
    value: modals?.[0]?.sku,
  });
  const currentModal = modals.find(cont => cont.sku === modal.value);

  const onAddonDetailsClick = sku => {
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.top = `-${window.scrollY}px`;
    setModal({ shown: true, value: sku });
  };

  const onClickClose = () => {
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
    setModal(prev => ({ ...prev, shown: false }));
  };

  return {
    modal: { ...currentModal, ...modal, onClickClose },
    onAddonDetailsClick,
  };
};
